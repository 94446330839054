import React from 'react'

export default function OpeningImage() {
  return (
    <div className='image-container'>
        <h1>
            <p>
            Professional and Personalized Tax Preparation Services in Washington
            </p> 
        </h1>
    </div>
  )
}
